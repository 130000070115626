<template>
    <div class='page'>
        <div class="body1">
            <img class="product1" src="@/assets/images/care1.png" alt="">
            <div class="info txt_center wow fadeInDown">
                <div class="title">{{ $t('care.value1') }}</div>
                <div class="desc">{{ $t('care.value2') }}</div>
            </div>
        </div>
        <!-- 产品展示 -->
        <div class="body2 dis_flex">
            <img class="product2" src="@/assets/images/product2.png" alt="">
            <div class="left wow fadeInLeft">
                <div class="title dis_flex items_center ">
                    <img src="@/assets/images/index2.png" alt="">
                    {{ $t('care.value3') }}
                </div>
                <!-- tab切换 -->
                <div class="tabs">
                    <div class="tab" v-for="(item, index) in tabs" :key="index">
                        <div class="tab-title dis_flex items_center" :style="{'paddingLeft': index == 0 ? '25px' : '0'}" @click="collapse(item, index)">
                            <img src="@/assets/images/arrow.png" :class="[item.isCollapsed ? 'down' : 'up']" alt="" v-if="index != 0">
                            {{ $i18n.locale == 'zh' ? item.title : item.titleEn }}
                        </div>
                        <div class="childs" :class="[item.isCollapsed ? 'hide' : 'show']" v-if="item.child">
                            <div class="child" :class="{'active': index == current && currentChild == childIndex}" v-for="(child, childIndex) in item.child" :key="childIndex" @click="changeTab(index, childIndex)">
                                {{ $i18n.locale == 'zh' ? child.title : child.titleEn }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="items dis_flex wrap jus_between" v-if="categoryCareList.length">
                    <div class="item dis_flex" v-for="(item, index) in categoryCareList" :key="index"
                        @click="$goPage(`/careDetail?id=${item.id}`)">
                        <div class="imgBox">
                            <img :src="$utils.getImgUrl(item.coverImg)" alt="" v-if="item.coverImg">
                            <img src="@/assets/images/product3.png" alt="" v-else>
                        </div>
                        <div class="item-right">
                            <div class="time">{{ $moment(item.createTime).format('YYYY-MM-DD') }}</div>
                            <div class="title sheng_1">{{ $i18n.locale == 'zh' ? item.title : item.titleEn }}</div>
                            <div class="desc sheng_2">{{ $i18n.locale == 'zh' ? item.introduction : item.introductionEn }}</div>
                            <div class="more">{{ $t('care.value9') }}</div>
                        </div>
                    </div>
                </div>
                <div class="empty" v-else>
                    {{ $t('empty') }}
                </div>
                <el-pagination background layout="prev, pager, next" :current-page="page" :page-size="size" :total="total" @current-change="currentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { careCategoryAll, careList } from '@/api/index'
export default {
    data() {
        return {
            current: 0,
            currentChild: 0,
            page: 1,
            total: 0,
            size: 10,
            tabs: [],
            categoryCareList: []
        }
    },
    // computed: {
    //     tabs(){
    //         return [this.$t('care.value4'), this.$t('care.value5'), this.$t('care.value6')]
    //     }
    // },
    created() {
        this.$nextTick(() => {   //页面渲染完，在执行
            new this.$wow({
                live: false
            }).init()
        })
        this.careCategoryAll()
    },
    methods: {
        careCategoryAll() {
            careCategoryAll().then(res => {
                if (res.code == 0) {
                    res.data.forEach(item => {
                        this.$set(item, 'isCollapsed', true)
                    })
                    res.data.unshift({
                        id: null,
                        title: '全部',
                        titleEn: 'All'
                    })
                    this.tabs = res.data
                    this.getcareList()

                }
            })
        },
        collapse(item, index){
            console.log('index', index);
            if(index == 0){
                this.changeTab(0, 0)
            }else{
                item.isCollapsed = !item.isCollapsed
                this.changeTab(index, null)
            }
            
        },
        changeTab(index, childIndex) {
            this.current = index
            this.currentChild = childIndex
            this.page = 1
            this.getcareList()

        },
        currentChange(val) {
            this.page = val
            this.getcareList()

        },
        getcareList() {
            let categoryId = null
            if(this.currentChild === null){
                categoryId = this.tabs[this.current].id
            }else if(this.current != 0){
                categoryId = this.tabs[this.current].child[this.currentChild].id
            }
            careList({ categoryId, page: this.page, size: this.size }).then(res => {
                if (res.code == 0) {
                    this.categoryCareList = res.data.records
                    this.total = res.data.total
                }
            })
        }
    },
}
</script>
<style scoped lang='scss'>
.body1 {
    position: relative;
    width: 100%;
    height: 800px;
    padding-top: 428px;
    box-sizing: border-box;

    .product1 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .info {
        color: #FFFFFF;
        position: relative;

        .title {
            font-size: 60px;
            font-weight: bold;
        }

        .desc {
            font-size: 22px;
        }
    }
}

.body2 {
    padding: 100px 150px 44px;
    min-height: 800px;
    box-sizing: border-box;
    position: relative;

    .product2 {
        position: absolute;
        left: 0;
        top: 460px;
        width: 260px;
        object-fit: cover;
    }

    .left {
        z-index: 1;

        .title {
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 38px;

            img {
                width: 72px;
                height: 72px;
                margin-right: 8px;

            }
        }

        .tabs {
            width: 240px;

            .tab {
                font-size: 20px;
                font-weight: bold;
                padding: 14px 0;
                border-top: 2px solid #D7D7D7;
                cursor: pointer;
                .tab-title{
                    font-size: 20px;
                    font-weight: bold;
                    img{
                        width: 20px;
                        height: 20px;
                        margin-right: 4px;
                        transition: .3s;
                        &.down{
                            transform: rotate(180deg);
                        }
                        &.up{
                            transform: rotate(0);
                        }
                    }
                }
                .childs{
                    padding-left: 25px;
                    max-height: 0;
                    transition: .3s;
                    overflow: hidden;
                    &.hide{
                        max-height: 0;
                    }
                    &.show{
                        max-height: 1000px;
                    }
                    .child{
                        color: #717171;
                        font-size: 18px;
                        margin-top: 16px;
                        &:first-child{
                            margin-top: 22px;
                        }
                        &.active {
                            color: #101F93;
                        }
                    }
                }
                
            }
        }
    }

    .right {
        margin-left: 80px;
        flex: 1;
        width: calc(100% - 620px);
        .empty{
            width: 100%;
            height: 300px;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            margin-top: 150px;
        }
        .item {
            cursor: pointer;
            padding: 40px 0;
            border-bottom: 1px solid #D0D0D0;
            width: 100%;
            &:last-child {
                border-bottom: none;
            }

            .imgBox {
                width: 180px;
                height: 180px;
                position: relative;
                flex-shrink: 0;
                margin-right: 50px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .item-right {
                position: relative;
                width: calc(100% - 230px);
                .time {
                    color: #9BBAC4;
                    font-size: 20px;
                    font-weight: bold;
                }

                .title {
                    color: #101F93;
                    font-size: 26px;
                    font-weight: bold;
                    margin: 5px 0 11px;
                }

                .desc {
                    color: #7D7D7D;
                    font-size: 16px;
                    font-weight: bold;
                }

                .more {
                    color: #9BBAC4;
                    font-size: 16px;
                    font-weight: bold;
                    margin-top: 36px;

                }
            }
        }

        .el-pagination {
            text-align: right;

        }
    }
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #001E77;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
    color: #fff;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #001E77;
}

::v-deep .el-pagination button {
    min-width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
}

::v-deep .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    min-width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
}

::v-deep .el-pager li {
    min-width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
}


@media only screen and (min-width: 1400px) and (max-width: 1700px) {
    .body2 {
        .product2 {
            width: 200px;
        }

        .left {
            .title {
                font-size: 32px;
            }

            .tabs {
                width: 150px;
            }
        }
        .right{
            width: calc(100% - 530px);
        }
    }
}

@media only screen and (max-width: 1400px) {
    .body2 {
        .product2 {
            width: 160px;
        }

        .left {
            .title {
                font-size: 26px;
            }

            .tabs {
                width: 130px;
            }
        }
        .right{
            width: calc(100% - 510px);
        }
    }
}
</style>
